import React, { memo, useContext, Fragment } from 'react';
import { Panel, DataTable } from '@sdflc/ui';
import { MenuHeader, DefaultLayout, PageLoading, BusinessHoursOverlay } from '../components';
import { withProviders } from '../hoc';
import { SquareUpContext } from '../contexts';
import { colors, scheduleColumns } from '../config';

const MenuPage = memo(() => {
  const { firstTimeLoading, error, arenaSchedule, loadingSources, initialSourceLoading } = useContext(SquareUpContext);

  if (firstTimeLoading || initialSourceLoading) {
    return <PageLoading />;
  }

  if (arenaSchedule.length === 0) {
    return <Text>No schedule found</Text>;
  }

  return (
    <Fragment>
      <DefaultLayout title='Oakrdige Arena Schedule'>
        <MenuHeader title='Arena Schedule' />
        <Panel padding={'20px'} overflow='auto'>
          <DataTable
            idField='id'
            columns={scheduleColumns}
            data={arenaSchedule}
            selected={[]}
            getRowAttributes={({ row }) => {
              if (row.gameNo) {
                return {
                  rowCustomStyle: {
                    backgroundColor: `rgba(0, 255, 0, 0.1) !important`,
                  },
                };
              }
            }}
          />
        </Panel>
        <BusinessHoursOverlay />
      </DefaultLayout>
    </Fragment>
  );
});

MenuPage.displayName = 'MenuPage';

MenuPage.propTypes = {};

MenuPage.defaultProps = {};

export default withProviders(MenuPage);
